import { Authority } from "./permission/auth";

export class User {
    id: string = "";
    name: string = "";
    is_admin: boolean = false;
    accessibility: Accessibility = new Accessibility();
    data: UserData = new UserData();
    mark: UserMark = new UserMark();
    authority = new Authority();
}

export class UserInfo {
    id: string = "";
    name: string = "";
    email: string = "";
    label: string = "";
    create_time: string = "";
}

export class UserInfoBank {
    infos: UserInfo[] = [];
    isBackend: boolean = false;
}
export class Accessibility {
    has_crown: boolean = false;
    show_tailor_shelf: boolean = false;
    show_higher_collect: boolean = false;
    can_visit_material: boolean = false;
}

export class UserData {
    remove_corrects: number = 0;
    own_old_exercise: boolean = false;
    own_old_word: boolean = false;
    own_old_pick: boolean = false;
}

export class UserMark {
    user_index: number = 0;
    user_id: string = "";
    text: string = "";
    qr: boolean[][] = [];
    qr_reverse: boolean[][] = [];
    qr_rotate: boolean[][] = [];
}

export enum LoginKind {
    Account = 0,
    Phone = 1,
    Email = 2,
}
